/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function() {
                /**
                 *  Add shrink class to header on scroll
                 */
                var $body = $("body"),
                    $threshold = 100,
                    $window = $(window);

                $window.scroll(function() {
                    $scroll_position = $window.scrollTop();
                    if ($scroll_position > $threshold) {
                        $body.addClass("shrink");
                    } else {
                        $body.removeClass("shrink");
                    }
                });

                $(".menu-trigger").on("click", function(e) {
                    e.preventDefault();
                    if ($body.hasClass("menu-open")) {
                        $body.removeClass("menu-open");
                    } else {
                        $body.addClass("menu-open");
                    }
                });

                /**
                 *  Mobile menu sub menu functionality
                 */

                  $('.menus.mobile .menu-item-has-children > a').on('click', function (e) {

                        e.preventDefault();

                        $(this).parent().toggleClass('active-sub');

                        var panel = $(this).next('.sub-menu');

                        if (panel[0].style.maxHeight) {
                          panel[0].style.maxHeight = null;
                        } else {
                          panel[0].style.maxHeight = panel[0].scrollHeight + "px";
                        } 

                  });

                /**
                 *  Accordion toggle functionality
                 */

                var allPanels = $(".accordion .panel").hide();
                var allPanelParents = $(".accordion .item");

                $(".accordion .toggle").on("click", function(e) {
                    $this = $(this);
                    $target = $this.next();

                    $(".accordion").addClass("activated");

                    if (!$target.hasClass("active")) {
                        allPanelParents.removeClass("item-active");
                        allPanels.removeClass("active").slideUp();
                        $target.addClass("active").slideDown();
                        $this.parent().addClass("item-active");
                    } else {
                        $this.parent().removeClass("item-active");
                        $this
                            .next()
                            .removeClass("active")
                            .slideUp();
                        $(".accordion").removeClass("activated");
                    }

                    return false;
                });

                /**
                 *  scroll to section for btn
                 */
                $("[href*='#']").click(function(e) {

                    href = $(this).attr("href");

                    split = href.split('#')[0];
                    currentURL = window.location.href.split('#')[0];

                    if (split === currentURL) {
                        e.preventDefault();
                        str = '#' + href.substring(href.indexOf("#") + 1);
                        $('body').removeClass('menu-open');
                        if (str.length > 1) {
                            $("html, body").animate(
                                {
                                    scrollTop: $(str).offset().top - 66
                                },
                                1000
                            );
                        }
                    }
                });

                /**
                 *  Activate menu item based on scroll position
                 */

                var lastId,
                    topMenu = $(".main-menu"),
                    topMenuLinks = $('.main-menu .menu-navigation'),
                    topMenuHeight = 73;

                // All list items
                (menuItems = topMenuLinks.find("a")),
                    // Anchors corresponding to menu items
                    (scrollItems = menuItems.map(function() {
                        var item = $($(this).attr("href"));
                        if (item.length) {
                            return item;
                        }
                    }));

                // Bind to scroll
                $(window).scroll(function() {
                    // Get container scroll position
                    var fromTop = $(this).scrollTop() + 225;

                    // Get id of current scroll item
                    var cur = scrollItems.map(function() {
                        if ($(this).offset().top < fromTop) return this;
                    });
                    // Get the id of the current element
                    cur = cur[cur.length - 1];
                    var id = cur && cur.length ? cur[0].id : "";

                    if (lastId !== id) {
                        lastId = id;
                        lastIdElement = $("[href='#" + id + "']");
                        // Set/remove active class
                        menuItems
                            .parent()
                            .removeClass("active")
                            .end()
                            .filter(lastIdElement)
                            .parent()
                            .addClass("active");
                    }
                });

                /**
                 *  Add body class on scroll up
                 */

                    var position = $(window).scrollTop(); 

                    // should start at 0

                    $(window).scroll(function() {
                        var scroll = $(window).scrollTop();
                        if(scroll > position) {
                            $('body').removeClass('scroll-up');
                        } else {
                             $('body').addClass('scroll-up');
                        }
                        position = scroll;
                    });

                /**
                 * Product filter select dropdown
                 */
                $('.select-filter').on('change', function (e) {

                    option = $(this).find(":selected");
                    href = option.attr('href');
                    title = option.data('title');
                    e.preventDefault();

                    var filterValue = option.attr('data-filter');
                    $container.isotope({ filter: filterValue });
                    window.history.replaceState('Object', title, href);

                });

                
                var $container = $('.product-list-container');
                // use imagesLoaded, instead of window.load
                $container.imagesLoaded( function() {
                    $container.isotope({
                        layoutMode: 'fitRows',
                        itemSelector: '.item',
                        percentPosition: true,
                    });
                })

                $initialFilter = $container.data('initial-filter');
                if ( $initialFilter ) {
                    $container.isotope({ filter: '.' + $initialFilter });
                }
                

                $('.product-filters').on( 'click', 'a', function(e) {

                    href = $(this).attr('href');
                    title = $(this).data('title');
                    e.preventDefault();

                    var filterValue = $(this).attr('data-filter');
                    $container.isotope({ filter: filterValue });
                    window.history.replaceState('Object', title, href);

                });

                // change active class on buttons
                $('.product-filters').each( function( i, buttonGroup ) {
                    var $buttonGroup = $( buttonGroup );
                    $buttonGroup.on( 'click', 'a', function() {
                        $buttonGroup.find('.active').removeClass('active');
                        $( this ).addClass('active');
                    });
                });

            }
        },
        /**
         *  FAQ Block
         */
        faq_block: {
            init: function() {
                /**
                 *  Accordion functionality
                 */

                var acc = document.getElementsByClassName("question");
                var i;

                for (i = 0; i < acc.length; i++) {
                    acc[i].onclick = function(e) {
                        e.preventDefault();

                        this.classList.toggle("active");
                        var panel = this.nextElementSibling;

                        if (panel.style.maxHeight) {
                            panel.style.maxHeight = null;
                        } else {
                            panel.style.maxHeight = panel.scrollHeight + "px";
                        }
                    };
                }
            }
        },
        info_slider_block: {
            init: function() {
                /**
                 *  Slick Slider
                 *  Intialize slick slider on info slider block
                 *  and support multiple instances with "each"
                 *  @link http://kenwheeler.github.io/slick/
                 */
                $(".block.info-slider").each(function() {

                    $slideId = $(this).attr("id");
                    $gallerySlickElement = $("#" + $slideId + " .info-slider-slides-container");
                    $gallerySlickElement.slick({
                        dots: true,
                        infinite: false,
                        autoplay: false,
                    
                        appendArrows: $(
                            "#" + $slideId + " .slick-nav-container"
                        ),
                        appendDots: $("#" + $slideId + " .slick-nav-container"),
                        prevArrow:
                            '<div class="slick-prev"><i class="icon-back"></i></div>',
                        nextArrow:
                            '<div class="slick-next"><i class="icon-next"></i></div>'
                    });
                });
            }
        },
        single_products: {
            init: function() {
                /**
                 *  Slick Slider
                 *  Intialize slick slider on info slider block
                 *  and support multiple instances with "each"
                 *  @link http://kenwheeler.github.io/slick/
                 */
                $(".product-container .gallery-container").each(function() {

                    $(this).slick({
                        dots: true,
                        arrows:false,
                        infinite: false,
                        autoplay: false,
                    });
                });

                $( ".product-container .gallery-container.desktop .image-wrap img" ).each(function( index ) {

                    $imageElementDom = $(this).get(0);
                    $productContainer = $('.product-container .product-information').get(0);

                    new Drift($imageElementDom, {
                        paneContainer: $productContainer,
                        containInline: true,
                        hoverBoundingBox: true,
                        hoverDelay: 250,
                    });
                });

                

            }
        },
        
        
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(
                document.body.className.replace(/-/g, "_").split(/\s+/),
                function(i, classnm) {
                    UTIL.fire(classnm);
                    UTIL.fire(classnm, "finalize");
                }
            );

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
